<template>
  <div class="py-7 container-fluid">
    <div class="card">
      <div class="card-header pb-0">
        <p class="card-title">Checking....</p>
      </div>
      <div class="row">
        <div class="col-lg-40">
          <div class="row mt-4">
            <div class="row">
              <div class="col-lg-2 col-md-6 col-12">
                <div class="card m-3 w-100"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showComponent: false, // Inicialmente oculto
    };
  },
  mounted() {
    this.login();
  },
  methods: {
    async login() {
      try {
        const response = await this.$api.get("/v1/login");
        const loginUrl = response.data.loginUrl;
        window.location.href = loginUrl;
      } catch (error) {
        console.error("Error al iniciar sesión:", error);
      }
    },
  },
};
</script>
