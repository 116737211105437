<template>
  <div class="py-7 container-fluid">
    <div class="card">
      <div class="card-header pb-0">
        <div v-if="loading">Loading...</div>
        <div v-else>
          <h6 v-if="deployments.length === 0">
            Stacks not found in {{ namespace }}
          </h6>
          <h6 v-else>Project: {{ namespace }}</h6>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12">
          <div class="row mt-4">
            <div class="row">
              <div
                class="col-lg-3 col-md-6 col-12"
                v-for="deployment in deployments"
                :key="deployment.Name"
              >
                <div class="card m-3">
                  <div class="card-body">
                    <h6 class="card-title">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="bi bi-stack"
                        viewBox="0 0 16 16"
                      >
                        <path
                          d="m14.12 10.163 1.715.858c.22.11.22.424 0 .534L8.267 15.34a.6.6 0 0 1-.534 0L.165 11.555a.299.299 0 0 1 0-.534l1.716-.858 5.317 2.659c.505.252 1.1.252 1.604 0l5.317-2.66zM7.733.063a.6.6 0 0 1 .534 0l7.568 3.784a.3.3 0 0 1 0 .535L8.267 8.165a.6.6 0 0 1-.534 0L.165 4.382a.299.299 0 0 1 0-.535z"
                        />
                        <path
                          d="m14.12 6.576 1.715.858c.22.11.22.424 0 .534l-7.568 3.784a.6.6 0 0 1-.534 0L.165 7.968a.299.299 0 0 1 0-.534l1.716-.858 5.317 2.659c.505.252 1.1.252 1.604 0z"
                        />
                      </svg>
                      {{ deployment.name }}
                    </h6>
                    <div
                      v-if="deployment.replicas !== 0"
                      class="h8 mb-0 font-weight-bold text-gray-800"
                    >
                      <div class="h8 mb-0 font-weight-bold text-gray-800">
                        <div>
                          <argon-badge
                            v-if="deployment.status === 'Starting'"
                            variant="fill"
                            size="sm"
                            color="warning"
                          >
                            {{ deployment.status }}
                          </argon-badge>
                          <argon-badge
                            v-if="deployment.status === 'Ready'"
                            variant="fill"
                            size="sm"
                            color="success"
                          >
                            {{ deployment.status }}
                          </argon-badge>
                        </div>
                        <div>
                          <argon-badge
                            v-if="deployment.status === 'Ready'"
                            variant="fill"
                            size="sm"
                            color="success"
                          >
                            <i class="bi bi-alarm-fill"></i>
                            Active: {{ deployment.durationTime }}
                          </argon-badge>
                        </div>
                      </div>
                    </div>
                    <div
                      v-if="deployment.replicas === 0"
                      class="h8 mb-0 font-weight-bold text-gray-800"
                    >
                      <argon-badge variant="fill" size="md" color="warning">
                        Stopped
                      </argon-badge>
                    </div>
                    <!-- <div
                      v-if="
                        deployment.status === 'True' &&
                        deployment.replicas === 0
                      "
                      class="h8 mb-0 font-weight-bold text-gray-800"
                    >
                      <argon-badge variant="fill" size="sm" color="danger">
                        Stopped
                      </argon-badge>
                    </div> -->
                    <argon-badge
                      v-for="annotation in deployment.annotations"
                      :key="annotation"
                      variant="fill"
                      size="sm"
                      color="secondary"
                    >
                      {{ annotation }}
                    </argon-badge>
                    <div v-for="pod in deployment.pod" :key="pod.name">
                      <argon-badge variant="fill" size="sm" color="secondary">
                        Cpu {{ pod.totalCpuLimit }}
                      </argon-badge>
                      <argon-badge variant="fill" size="sm" color="secondary">
                        Memory {{ pod.totalMemoryLimit }}
                      </argon-badge>
                    </div>
                    <div v-for="service in deployment.service" :key="service">
                      <argon-badge variant="fill" size="sm" color="secondary">
                        Ip {{ service.ip }}
                      </argon-badge>
                      <argon-badge variant="fill" size="sm" color="secondary">
                        Domain {{ deployment.name }}.{{
                          deployment.namespace
                        }}.svc.cluster.local
                      </argon-badge>
                    </div>
                    <p></p>
                    <button
                      v-if="deployment.replicas === 0"
                      type="submit"
                      class="btn btn-primary btn-xs"
                      @click="scale(deployment, 1)"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="bi bi-play-fill"
                        viewBox="0 0 16 16"
                      >
                        <path
                          d="m11.596 8.697-6.363 3.692c-.54.313-1.233-.066-1.233-.697V4.308c0-.63.692-1.01 1.233-.696l6.363 3.692a.802.802 0 0 1 0 1.393"
                        />
                      </svg>
                      Start
                    </button>
                    <button
                      v-if="
                        deployment.replicas >= 1 &&
                        deployment.status === 'Ready'
                      "
                      type="submit"
                      class="btn btn-primary btn-xs"
                      @click="scale(deployment, 0)"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="bi bi-stop-fill"
                        viewBox="0 0 16 16"
                      >
                        <path
                          d="M5 3.5h6A1.5 1.5 0 0 1 12.5 5v6a1.5 1.5 0 0 1-1.5 1.5H5A1.5 1.5 0 0 1 3.5 11V5A1.5 1.5 0 0 1 5 3.5"
                        />
                      </svg>
                      Stop
                    </button>

                    <div
                      v-if="
                        deployment.replicas >= 1 &&
                        deployment.status === 'Ready'
                      "
                    >
                      <a
                        href="#"
                        data-bs-toggle="modal"
                        :data-bs-target="'#conectar-' + deployment.id"
                        class="btn btn-primary btn-xs"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          class="bi bi-pc-display-horizontal"
                          viewBox="0 0 16 16"
                        >
                          <path
                            d="M1.5 0A1.5 1.5 0 0 0 0 1.5v7A1.5 1.5 0 0 0 1.5 10H6v1H1a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1v-3a1 1 0 0 0-1-1h-5v-1h4.5A1.5 1.5 0 0 0 16 8.5v-7A1.5 1.5 0 0 0 14.5 0zm0 1h13a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5v-7a.5.5 0 0 1 .5-.5M12 12.5a.5.5 0 1 1 1 0 .5.5 0 0 1-1 0m2 0a.5.5 0 1 1 1 0 .5.5 0 0 1-1 0M1.5 12h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1 0-1M1 14.25a.25.25 0 0 1 .25-.25h5.5a.25.25 0 1 1 0 .5h-5.5a.25.25 0 0 1-.25-.25"
                          />
                        </svg>
                        Connect
                      </a>
                      <div
                        class="modal fade"
                        :id="'conectar-' + deployment.id"
                        tabindex="-1"
                        aria-labelledby="ModalLabel"
                        aria-hidden="true"
                      >
                        <div class="modal-dialog">
                          <div class="modal-content">
                            <div
                              v-for="service in deployment.service"
                              :key="service.name"
                            >
                              <div class="modal-header">
                                <h5 class="modal-title" id="conectar">
                                  Windows
                                </h5>
                                <button
                                  type="button"
                                  class="btn-close"
                                  data-bs-dismiss="modal"
                                  aria-label="Close"
                                ></button>
                              </div>

                              <div class="modal-body">
                                <div
                                  class="h8 mb-0 font-weight-bold text-gray-800"
                                >
                                  1 - Inicia cmd o powershell y ejecuta el
                                  siguiente (solo una vez)
                                </div>
                                <p></p>
                                <div
                                  class="h8 mb-0 font-weight-bold text-gray-800"
                                >
                                  cmdkey /generic:TERMSRV/{{
                                    service.ip
                                  }}
                                  /user:developer /pass:{{
                                    deployment.remotePassword
                                  }}
                                </div>
                                <p></p>
                                <div
                                  class="h8 mb-0 font-weight-bold text-gray-800"
                                >
                                  2 - Descarga el fichero RDP
                                </div>
                                <p></p>

                                <button
                                  type="submit"
                                  @click="
                                    downloadRDPFile(
                                      deployment,
                                      'windows',
                                      service.ip
                                    )
                                  "
                                  class="btn btn-primary btn-xs"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    class="bi bi-cloud-download"
                                    viewBox="0 0 16 16"
                                  >
                                    <path
                                      d="M4.406 1.342A5.53 5.53 0 0 1 8 0c2.69 0 4.923 2 5.166 4.579C14.758 4.804 16 6.137 16 7.773 16 9.569 14.502 11 12.687 11H10a.5.5 0 0 1 0-1h2.688C13.979 10 15 8.988 15 7.773c0-1.216-1.02-2.228-2.313-2.228h-.5v-.5C12.188 2.825 10.328 1 8 1a4.53 4.53 0 0 0-2.941 1.1c-.757.652-1.153 1.438-1.153 2.055v.448l-.445.049C2.064 4.805 1 5.952 1 7.318 1 8.785 2.23 10 3.781 10H6a.5.5 0 0 1 0 1H3.781C1.708 11 0 9.366 0 7.318c0-1.763 1.266-3.223 2.942-3.593.143-.863.698-1.723 1.464-2.383z"
                                    />
                                    <path
                                      d="M7.646 15.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 14.293V5.5a.5.5 0 0 0-1 0v8.793l-2.146-2.147a.5.5 0 0 0-.708.708l3 3z"
                                    />
                                  </svg>
                                  Descargar
                                </button>
                                <p></p>
                                <div
                                  class="h8 mb-0 font-weight-bold text-gray-800"
                                >
                                  3 - Click sobre el fichero
                                  {{ deployment.name }}-{{
                                    deployment.namespace
                                  }}-windows.rdp para iniciar
                                </div>
                              </div>
                              <div class="modal-header">
                                <h5 class="modal-title" id="conectar">Linux</h5>
                              </div>
                              <div class="modal-body">
                                <div
                                  class="h8 mb-0 font-weight-bold text-gray-800"
                                >
                                  1 - Descarga el fichero RDP
                                </div>
                                <p></p>
                                <button
                                  @click="
                                    downloadRDPFile(
                                      deployment,
                                      'linux',
                                      service.ip
                                    )
                                  "
                                  type="submit"
                                  class="btn btn-primary btn-xs"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    class="bi bi-cloud-download"
                                    viewBox="0 0 16 16"
                                  >
                                    <path
                                      d="M4.406 1.342A5.53 5.53 0 0 1 8 0c2.69 0 4.923 2 5.166 4.579C14.758 4.804 16 6.137 16 7.773 16 9.569 14.502 11 12.687 11H10a.5.5 0 0 1 0-1h2.688C13.979 10 15 8.988 15 7.773c0-1.216-1.02-2.228-2.313-2.228h-.5v-.5C12.188 2.825 10.328 1 8 1a4.53 4.53 0 0 0-2.941 1.1c-.757.652-1.153 1.438-1.153 2.055v.448l-.445.049C2.064 4.805 1 5.952 1 7.318 1 8.785 2.23 10 3.781 10H6a.5.5 0 0 1 0 1H3.781C1.708 11 0 9.366 0 7.318c0-1.763 1.266-3.223 2.942-3.593.143-.863.698-1.723 1.464-2.383z"
                                    />
                                    <path
                                      d="M7.646 15.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 14.293V5.5a.5.5 0 0 0-1 0v8.793l-2.146-2.147a.5.5 0 0 0-.708.708l3 3z"
                                    />
                                  </svg>
                                  Descargar
                                </button>
                                <p></p>
                                <div
                                  class="h8 mb-0 font-weight-bold text-gray-800"
                                >
                                  2 - Ejecuta desde un terminal para iniciar
                                </div>
                                <p></p>
                                <div
                                  class="h8 mb-0 font-weight-bold text-gray-800"
                                >
                                  remmina -c {{ deployment.name }}-{{
                                    deployment.namespace
                                  }}-linux.rdp
                                </div>
                              </div>
                            </div>
                            <div class="modal-footer">
                              <button
                                type="button"
                                class="btn btn-secondary"
                                data-bs-dismiss="modal"
                              >
                                Close
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      v-if="
                        deployment.replicas >= 1 &&
                        deployment.status === 'Ready'
                      "
                    >
                      <a
                        href="#"
                        data-bs-toggle="modal"
                        :data-bs-target="'#pass-' + deployment.id"
                        class="btn btn-primary btn-xs"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          class="bi bi-key"
                          viewBox="0 0 16 16"
                        >
                          <path
                            d="M0 8a4 4 0 0 1 7.465-2H14a.5.5 0 0 1 .354.146l1.5 1.5a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0L13 9.207l-.646.647a.5.5 0 0 1-.708 0L11 9.207l-.646.647a.5.5 0 0 1-.708 0L9 9.207l-.646.647A.5.5 0 0 1 8 10h-.535A4 4 0 0 1 0 8m4-3a3 3 0 1 0 2.712 4.285A.5.5 0 0 1 7.163 9h.63l.853-.854a.5.5 0 0 1 .708 0l.646.647.646-.647a.5.5 0 0 1 .708 0l.646.647.646-.647a.5.5 0 0 1 .708 0l.646.647.793-.793-1-1h-6.63a.5.5 0 0 1-.451-.285A3 3 0 0 0 4 5"
                          />
                          <path d="M4 8a1 1 0 1 1-2 0 1 1 0 0 1 2 0" />
                        </svg>
                        Credentials
                      </a>
                      <div
                        class="modal fade"
                        :id="'pass-' + deployment.id"
                        tabindex="-1"
                        aria-labelledby="ModalLabel"
                        aria-hidden="true"
                      >
                        <div class="modal-dialog">
                          <div class="modal-content">
                            <div class="modal-header">
                              <h5 class="modal-title" id="conectar">
                                Credentials
                              </h5>
                              <button
                                type="button"
                                class="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                              ></button>
                            </div>

                            <div class="modal-body">
                              <div
                                class="h8 mb-0 font-weight-bold text-gray-800"
                              >
                                User: developer
                              </div>
                              <div
                                class="h8 mb-0 font-weight-bold text-gray-800"
                              >
                                Password: {{ deployment.remotePassword }}
                              </div>
                            </div>
                            <div class="modal-footer">
                              <button
                                type="button"
                                class="btn btn-secondary"
                                data-bs-dismiss="modal"
                              >
                                Close
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        v-for="ingress in deployment.ingress"
                        :key="ingress.host"
                      >
                        <a
                          :href="'http://' + ingress.host"
                          target="_blank"
                          class="btn btn-primary btn-xs"
                          ><svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            class="bi bi-link-45deg"
                            viewBox="0 0 16 16"
                          >
                            <path
                              d="M4.715 6.542 3.343 7.914a3 3 0 1 0 4.243 4.243l1.828-1.829A3 3 0 0 0 8.586 5.5L8 6.086a1.002 1.002 0 0 0-.154.199 2 2 0 0 1 .861 3.337L6.88 11.45a2 2 0 1 1-2.83-2.83l.793-.792a4.018 4.018 0 0 1-.128-1.287z"
                            />
                            <path
                              d="M6.586 4.672A3 3 0 0 0 7.414 9.5l.775-.776a2 2 0 0 1-.896-3.346L9.12 3.55a2 2 0 1 1 2.83 2.83l-.793.792c.112.42.155.855.128 1.287l1.372-1.372a3 3 0 1 0-4.243-4.243L6.586 4.672z"
                            />
                          </svg>
                          FTP access
                        </a>
                      </div>
                    </div>
                    <div>
                      <a
                        href="#"
                        data-bs-toggle="modal"
                        :data-bs-target="'#config-' + deployment.id"
                        class="btn btn-primary btn-xs"
                      >
                        <i class="bi bi-gear-fill"></i>
                        Configure
                      </a>
                      <div
                        class="modal fade"
                        :id="'config-' + deployment.id"
                        tabindex="-1"
                        aria-labelledby="ModalLabel"
                        aria-hidden="true"
                      >
                        <div class="modal-dialog">
                          <div class="modal-content">
                            <div class="modal-header">
                              <h5 class="modal-title" id="conectar">
                                Configure Stack
                              </h5>
                              <button
                                type="button"
                                class="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                              ></button>
                            </div>

                            <div class="modal-body">
                              <div
                                v-if="deployment.stack.scheduleScale"
                                class="h8 mb-0 font-weight-bold text-gray-800"
                              >
                                <h7> Autostop Schedule: </h7>
                                <div>
                                  Cron:
                                  <span class="badge bg-secondary">{{
                                    deployment.stack.scheduleScale.cron
                                  }}</span>
                                </div>
                                <div>
                                  Status:
                                  <span
                                    v-if="
                                      deployment.stack.scheduleScale.disable
                                    "
                                    class="badge bg-secondary"
                                  >
                                    disable for 4 hours
                                  </span>
                                  <div
                                    v-if="
                                      deployment.stack.scheduleScale.disable
                                    "
                                  >
                                    Disable by:
                                    <span class="badge bg-secondary">
                                      {{
                                        deployment.stack.scheduleScale.disableBy
                                      }}
                                    </span>
                                  </div>
                                  <span v-else class="badge bg-secondary">
                                    enabled
                                  </span>
                                </div>
                                <p></p>
                                <div
                                  v-if="!deployment.stack.scheduleScale.disable"
                                >
                                  <button
                                    type="submit"
                                    class="btn btn-primary btn-xs"
                                    @click="autostop(deployment, true)"
                                  >
                                    <i class="bi bi-stop-fill"></i>
                                    Disable Autostop for 4 hours
                                  </button>
                                </div>
                                <div v-else>
                                  <button
                                    type="submit"
                                    class="btn btn-primary btn-xs"
                                    @click="autostop(deployment, false)"
                                  >
                                    <i class="bi bi-stop-fill"></i>
                                    Enable Autostop
                                  </button>
                                </div>
                              </div>
                              <div v-else>
                                <h7>Autostop Scheduler not configured</h7>
                              </div>
                              <hr />
                              <div
                                v-if="deployment.stack.scheduleSnapshot"
                                class="h8 mb-0 font-weight-bold text-gray-800"
                              >
                                <h7> AutoSnapshot Schedule: </h7>
                                <div>
                                  Cron:
                                  <span class="badge bg-secondary">{{
                                    deployment.stack.scheduleSnapshot.cron
                                  }}</span>
                                </div>
                                <div>
                                  Status:
                                  <span
                                    v-if="
                                      deployment.stack.scheduleSnapshot.disable
                                    "
                                    class="badge bg-secondary"
                                  >
                                    disable
                                  </span>
                                  <span v-else class="badge bg-secondary">
                                    enabled
                                  </span>
                                </div>
                              </div>
                              <div v-else>
                                <h7> Autosnapshot Scheduler not configured</h7>
                              </div>
                              <hr />

                              <div
                                v-if="deployment.stack"
                                class="h8 mb-0 font-weight-bold text-gray-800"
                              >
                                <h7> Stack info: </h7>
                                <div>
                                  Image stack:
                                  <span class="badge bg-secondary">{{
                                    deployment.stack.imageStack.name
                                  }}</span>
                                </div>
                                <div>
                                  New image stack:
                                  <div class="mb-3">
                                    <select
                                      required
                                      class="form-select form-select-sm"
                                      aria-label="Default select example"
                                      v-model="newImage"
                                      @focus="loadImageStack()"
                                    >
                                      <option disabled>
                                        Select image stack
                                      </option>
                                      <option
                                        v-for="(tag, index) in imagesStacks"
                                        :key="index"
                                        :value="tag"
                                      >
                                        {{ tag.name }}
                                      </option>
                                    </select>
                                  </div>
                                </div>
                                <p></p>
                                <div>
                                  <button
                                    type="submit"
                                    class="btn btn-primary btn-xs"
                                    @click="changeImageStack(deployment)"
                                  >
                                    <i class="bi bi-stack"></i>
                                    Change image stack
                                  </button>
                                </div>
                              </div>
                            </div>
                            <div class="modal-footer">
                              <button
                                type="button"
                                class="btn btn-secondary"
                                data-bs-dismiss="modal"
                              >
                                Close
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ArgonBadge from "@/components/ArgonBadge.vue";
import { useToast } from "vue-toastification";
import { mapGetters } from "vuex";

const toast = useToast();

export default {
  components: {
    ArgonBadge,
  },
  data() {
    return {
      deployments: [],
      loading: true,
      disableAutostopCheck: false,
      imagesStacks: [],
      newImage: null,
      intervalId: null,
    };
  },
  computed: {
    ...mapGetters("auth", ["user"]),
  },
  props: ["namespace"],
  mounted() {
    this.loadDeployments();
    this.intervalId = setInterval(this.loadDeployments, 5000); // Cargar despliegues cada 30 segundos
  },
  beforeUnmount() {
    if (this.intervalId) {
      clearInterval(this.intervalId); // Limpiar el intervalo cuando el componente se destruya
    }
  },
  methods: {
    async autostop(deployment, disable) {
      if (disable) {
        deployment.stack.scheduleScale.disable = true;
        deployment.stack.scheduleScale.disableBy = this.user.displayName;
      } else {
        deployment.stack.scheduleScale.disable = false;
      }
      try {
        const response = await this.$api.put(
          `/v1/deployment/autostop?user=${this.user.ID}`,
          deployment
        );
        if (response.status === 200) {
          toast.success("Disable autostop successful");
          this.loadDeployments();
        } else {
          toast.error("Disable autostop error");
        }
      } catch (error) {
        toast.error(error.response.data);
        this.disableAutostopCheck = true;
      }
    },
    async changeImageStack(deployment) {
      try {
        if (this.newImage == null) {
          toast.error("Select new image");
          return;
        }
        deployment.stack.imageStack = this.newImage;
        const response = await this.$api.put(
          `/v1/deployment/changeimagestack?user=${this.user.ID}`,
          deployment
        );
        if (response.status === 200) {
          toast.success("Change image stack successful");
          this.loadDeployments();
        } else {
          toast.error("Change image stack error");
        }
      } catch (error) {
        toast.error(error.response.data);
        this.disableAutostopCheck = true;
      }
    },
    async scale(deployment, replica) {
      try {
        const response = await this.$api.post(
          `/v1/deployment/scale?replica=${replica}&user=${this.user.ID}`,
          deployment
        );
        if (response.status === 200) {
          this.loadDeployments();
          if (replica == 0) {
            toast.success("Stop successful");
          } else {
            toast.success("Start successful");
          }
        }
      } catch (error) {
        toast.error("start error");
      }
    },
    async loadImageStack() {
      this.$api
        .get("/v1/image")
        .then((response) => {
          this.imagesStacks = response.data;
        })
        .catch((error) => {
          console.error("Error fetching images:", error);
        });
    },
    async loadDeployments() {
      this.$api
        .get(`/v1/deployment/${this.namespace}`)
        .then((response) => {
          this.deployments = response.data;
          this.loading = false;
        })
        .catch((error) => {
          console.error("Error fetching deployments:", error);
          this.loading = false;
        });
    },
    downloadRDPFile(deployment, so, serviceip) {
      const url = `/v1/utils/rdpfile/?so=${so}&serviceip=${serviceip}&name=${deployment.name}&remote_password=${deployment.remotePassword}`;
      this.$api
        .get(url, { responseType: "blob" })
        .then((response) => {
          const blob = new Blob([response.data], {
            type: "application/octet-stream",
          });
          const downloadUrl = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = downloadUrl;
          link.setAttribute("download", `${deployment.name}-${so}.rdp`);
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
        })
        .catch((error) => {
          console.error("Error downloading file:", error);
        });
    },
  },
};
</script>
