<template>
  <div class="py-7 container-fluid">
    <div class="card w-70">
      <div class="card-header pb-0">
        <h6>Update User: {{ user.displayName }}</h6>
      </div>
      <div class="card-body px-5 pt-5 pb-2">
        <div class="mb-3">
          <label for="exampleFormControlInput1" class="form-label">Name</label>
          <input
            readonly
            type="text"
            class="form-control"
            id="exampleFormControlInput1"
            :value="user.displayName"
            :style="{ width: inputWidth }"
          />
        </div>
        <div class="mb-3">
          <label for="exampleFormControlInput1" class="form-label">Email</label>
          <input
            readonly
            type="text"
            class="form-control"
            id="exampleFormControlInput1"
            :value="user.mail"
            :style="{ width: inputWidth }"
          />
        </div>
        <div v-if="!user.allNamespaces" class="mb-3">
          <label for="exampleFormControlInput1" class="form-label"
            >Namespaces</label
          >
          <select
            class="form-select"
            aria-label="Default select example"
            v-model="selectedNamespace"
            @change="selectNamespace()"
          >
            <option disabled>Select namespace</option>
            <option v-for="(ns, index) in namespaces" :key="index" :value="ns">
              {{ ns.name }}
            </option>
          </select>
        </div>
        <div v-if="!user.allNamespaces" class="mb-3">
          <div
            v-for="(namespace, index) in user.namespace"
            :key="index"
            class="badge bg-primary rounded-pill"
          >
            {{ namespace.name }}
            <span
              class="badge bg-danger rounded-pill cursor-pointer"
              @click="removeNamespaceTag(index)"
            >
              x</span
            >
          </div>
        </div>
        <div v-if="!user.allStacks" class="mb-3">
          <label for="exampleFormControlInput1" class="form-label"
            >Stacks</label
          >
          <select
            class="form-select"
            aria-label="Default select example"
            v-model="selectedStack"
            @change="selectStack()"
          >
            <option disabled>Select stack</option>
            <option v-for="(s, index) in stacks" :key="index" :value="s">
              {{ s.name }} / Namespace {{ s.namespace.name }}
            </option>
          </select>
        </div>
        <div v-if="!user.allStacks" class="mb-3">
          <div
            v-for="(stack, index) in user.stack"
            :key="index"
            class="badge bg-primary rounded-pill"
          >
            {{ stack.name }}
            <span
              class="badge bg-danger rounded-pill cursor-pointer"
              @click="removeStackTag(index)"
            >
              x</span
            >
          </div>
        </div>
        <div class="mb-3">
          <div class="form-check">
            <input
              class="form-check-input"
              type="checkbox"
              :value="user.allNamespaces"
              v-model="user.allNamespaces"
              id="flexCheckns"
            />
            <label class="form-check-label" for="flexCheckadmin">
              All namespaces
            </label>
          </div>
          <div class="form-check">
            <input
              class="form-check-input"
              type="checkbox"
              :value="user.allStacks"
              v-model="user.allStacks"
              id="flexCheckns"
            />
            <label class="form-check-label" for="flexCheckadmin">
              All stacks
            </label>
          </div>
          <div class="form-check">
            <input
              class="form-check-input"
              type="checkbox"
              :value="user.admin"
              v-model="user.admin"
              id="flexCheckadmin"
            />
            <label class="form-check-label" for="flexCheckadmin"> Admin </label>
          </div>
          <div class="form-check">
            <input
              class="form-check-input"
              type="checkbox"
              :value="user.active"
              v-model="user.active"
              id="flexCheckactive"
            />
            <label class="form-check-label" for="flexCheckactive">
              Active
            </label>
          </div>
        </div>
        <div class="mb-3">
          <button
            @click="updateUser(user)"
            type="button"
            class="btn btn-primary"
          >
            Update
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      user: Object,
      nsTags: [],
      stackTags: [],
      namespaces: [],
      stacks: [],
      selectedNamespace: Object,
      selectedStack: Object,
    };
  },
  computed: {},
  props: ["id"],
  mounted() {
    this.loadUser();
    this.loadNamespaces();
    this.loadStacks();
  },
  methods: {
    selectStack() {
      this.stackTags.push(this.selectedStack);
    },
    selectNamespace() {
      this.nsTags.push(this.selectedNamespace);
    },
    removeNamespaceTag(index) {
      this.nsTags.splice(index, 1);
    },
    removeStackTag(index) {
      this.stackTags.splice(index, 1);
    },
    updateUser() {
      this.user.namespace = this.nsTags;
      this.user.stack = this.stackTags;
      this.$api
        .put(`/v1/user/${this.id}`, this.user)
        .then(() => {
          console.log("usuario actualizado correcto");
          this.$router.push({ name: "User" });
        })
        .catch((error) => {
          console.error("Error update users:", error);
        });
    },
    loadUser() {
      this.$api
        .get(`/v1/user/${this.id}`)
        .then((response) => {
          console.log("usuario capturado correcto");
          this.user = response.data;
          if (this.user.stack === null) {
            this.user.stack = [];
            this.stackTags = this.user.stack;
          } else {
            this.stackTags = this.user.stack;
          }
          if (this.user.namespace === null) {
            this.user.namespace = [];
            this.nsTags = this.user.namespace;
          } else {
            this.nsTags = this.user.namespace;
          }
        })
        .catch((error) => {
          console.error("Error fetching users:", error);
        });
    },
    loadNamespaces() {
      console.log("cargando ns");
      this.$api
        .get("/v1/namespace")
        .then((response) => {
          this.namespaces = response.data;
          console.log("ns cargados " + response.data);
        })
        .catch((error) => {
          console.error("Error fetching ns:", error);
        });
    },
    loadStacks() {
      console.log("cargando stacks");
      this.$api
        .get("/v1/stack?nopaginate=1")
        .then((response) => {
          this.stacks = response.data.stacks;
          console.log("stack cargados " + response.data);
        })
        .catch((error) => {
          console.error("Error fetching stack:", error);
        });
    },
  },
};
</script>
