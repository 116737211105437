<template>
  <div class="card w-50">
    <div class="card-header pb-0">
      <h6>stack</h6>
    </div>
    <div class="card-body px-5 pt-5 pb-2">
      <div class="mb-3">
        <label for="exampleFormControlInput1" class="form-label">Name</label>
        <input
          required
          type="text"
          class="form-control form-control-sm"
          id="name"
          v-model="stack.name"
          :style="{ width: inputWidth }"
          :readonly="edit === true"
        />
      </div>
      <div v-if="!edit" class="mb-3">
        <label for="exampleFormControlInput1" class="form-label">Project</label>
        <select
          required
          class="form-select form-select-sm"
          aria-label="Default select example"
          v-model="stack.namespace"
          :disabled="edit === true"
        >
          <option disabled>Select project</option>
          <option v-for="(ns, index) in namespaces" :key="index" :value="ns">
            {{ ns.name }}
          </option>
        </select>
      </div>
      <div v-if="!edit && stack.namespace !== ''" class="form-check">
        <input
          class="form-check-input"
          type="checkbox"
          v-model="stack.createFromsnapshot"
          id="flexCheckactive"
        />
        <label class="form-check-label" for="flexCheckactive">
          Create from snapshot
        </label>
      </div>

      <div v-if="stack.createFromsnapshot" class="mb-3">
        <label for="exampleFormControlInput1" class="form-label"
          >Snapshot</label
        >
        <select
          class="form-select form-select-sm"
          aria-label="Default select example"
          v-model="stack.createFromSnapshotVolumeName"
          @focus="loadSnapshots()"
        >
          <option v-if="filteredSnapshots.length === 0" disabled selected>
            Not found snapshots in namespace {{ stack.namespace.name }}
          </option>
          <option v-else disabled selected>Select snapshot</option>
          <option
            v-for="(s, index) in filteredSnapshots"
            :key="index"
            :value="s.name"
          >
            {{ s.name }}
          </option>
        </select>
      </div>
      <div class="mb-3">
        <label for="exampleFormControlInput1" class="form-label"
          >Image template</label
        >
        <select
          required
          class="form-select form-select-sm"
          aria-label="Default select example"
          v-model="stack.imageStack"
        >
          <option disabled>Select image stack</option>
          <option
            v-for="(tag, index) in imagesStacks"
            :key="index"
            :value="tag"
          >
            {{ tag.name }}
          </option>
        </select>
      </div>
      <div class="mb-3">
        <label for="exampleFormControlInput1" class="form-label"
          >Machine type</label
        >
        <select
          class="form-select form-select-sm"
          aria-label="Default select example"
          v-model="stack.machine"
        >
          <option disabled>Select machine</option>
          <option
            v-for="(machine, index) in machines"
            :key="index"
            :value="machine"
          >
            {{ machine.name }} ({{ machine.memory }} x {{ machine.cpu }})
          </option>
        </select>
      </div>

      <div class="mb-3">
        <label for="exampleFormControlInput1" class="form-label"
          >Timezone</label
        >
        <select
          class="form-select form-select-sm"
          aria-label="Default select example"
          v-model="stack.timezone"
        >
          <option disabled selected>Select timezone</option>
          <option
            v-for="(tz, index) in timezones.name"
            :key="index"
            :value="tz"
          >
            {{ tz }}
          </option>
        </select>
      </div>
      <div v-if="edit" class="form-check">
        <input
          class="form-check-input"
          type="checkbox"
          v-model="generateNewPassword"
          id="password"
        />
        <label class="form-check-label" for="password">
          Generate new password
        </label>
      </div>
      <div v-if="edit" class="mb-3">
        <button @click="updateStack()" type="button" class="btn btn-primary">
          <span v-if="edit">Update</span>
        </button>
        <button @click="removeStack()" type="button" class="btn btn-danger">
          <span v-if="edit">Remove</span>
        </button>
      </div>
      <div v-else class="mb-3">
        <button @click="addStack()" type="button" class="btn btn-primary">
          <span>Add</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { useToast } from "vue-toastification";

const toast = useToast();
export default {
  props: ["id"],
  components: {},
  created() {
    if (this.id !== undefined && this.id !== null && this.id !== "") {
      this.loadStack(this.id);
      this.edit = true;
    }
  },
  data() {
    return {
      edit: false,
      stack: {
        name: "",
        namespace: "",
        timezone: "",
        imageStack: Object,
        machine: Object,
        createFromsnapshot: false,
        createFromSnapshotVolumeName: "",
      },
      generateNewPassword: false,
      namespaces: [],
      imagesStacks: [],
      resources: [],
      timezones: [],
      snapshots: [],
      machines: {},
    };
  },
  computed: {
    filteredSnapshots() {
      return this.snapshots.filter(
        (s) => s.stack.namespace.name === this.stack.namespace.name
      );
    },
  },
  mounted() {
    this.loadNamespaces();
    this.loadImageStack();
    this.loadTimezones();
    this.loadMachines();
  },
  methods: {
    loadStack(id) {
      this.$api
        .get(`/v1/stack/${id}`)
        .then((response) => {
          this.stack = response.data;
          console.log("stack cargado " + response.data);
        })
        .catch((error) => {
          console.error("Error fetching ns:", error);
        });
    },
    async removeStack() {
      try {
        const response = await this.$api.delete(`/v1/stack/${this.id}`);
        if (response.status >= 200 && response.status < 300) {
          toast.success("Stack delete successful");
          this.$router.push({ name: "Stack" }); // Redirigir a la página de stacks
        } else {
          const responseData = await response.json(); // Extraer el contenido JSON de la respuesta
          throw new Error(responseData.error || "Unknown error occurred");
        }
      } catch (error) {
        toast.error("Stack delete error - " + error.message);
      }
    },
    loadNamespaces() {
      console.log("cargando ns");
      this.$api
        .get("/v1/namespace")
        .then((response) => {
          this.namespaces = response.data;
          console.log("ns cargados " + response.data);
        })
        .catch((error) => {
          console.error("Error fetching ns:", error);
        });
    },
    loadImageStack() {
      this.$api
        .get("/v1/image")
        .then((response) => {
          this.imagesStacks = response.data;
        })
        .catch((error) => {
          console.error("Error fetching images:", error);
        });
    },
    loadMachines() {
      this.$api
        .get("/v1/utils/machines")
        .then((response) => {
          this.machines = response.data;
        })
        .catch((error) => {
          console.error("Error fetching machines:", error);
        });
    },
    loadTimezones() {
      this.$api
        .get("/v1/utils/timezone")
        .then((response) => {
          this.timezones = response.data;
        })
        .catch((error) => {
          console.error("Error fetching resources:", error);
        });
    },
    loadSnapshots() {
      this.$api
        .get("/v1/snapshot")
        .then((response) => {
          this.snapshots = response.data.snapshots;
        })
        .catch((error) => {
          console.error("Error fetching snapshots:", error);
        });
    },
    async addStack() {
      try {
        const response = await this.$api.post("/v1/stack", this.stack);
        if (response.status >= 200 && response.status < 300) {
          toast.success("Stack create successful");
          this.$router.push({ name: "Stack" });
        }
      } catch (error) {
        toast.error("Stack create error");
      }
    },
    async updateStack() {
      try {
        const response = await this.$api.put(
          `/v1/stack?generateNewPassword=${this.generateNewPassword}`,
          this.stack
        );
        if (response.status >= 200 && response.status < 300) {
          toast.success("Stack update successful");
          this.$router.push({ name: "Stack" });
        }
      } catch (error) {
        toast.error("Stack update error");
      }
    },
  },
};
</script>
