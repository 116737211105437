import { createApp } from "vue";
import App from "./App.vue";
import store from "./store";
import router from "./router";
import "./assets/css/nucleo-icons.css";
import "./assets/css/nucleo-svg.css";
// Importa los estilos de Bootstrap
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import 'bootstrap-icons/font/bootstrap-icons.css'
import Toast, { POSITION } from 'vue-toastification';
import 'vue-toastification/dist/index.css';
import ArgonDashboard from "./argon-dashboard";
import axios from 'axios';
import './assets/css/custom.css';

axios.defaults.baseURL = process.env.VUE_APP_BASE_URL;
axios.defaults.withCredentials = true;

axios.interceptors.request.use(
  config => {
    const token = store.state.auth.token;
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  error => Promise.reject(error)
);

axios.interceptors.response.use(
  response => response,
  error => {
    if (error.response && error.response.status === 401) {
      console.log("Redireccionando al usuario al login...");
      //store.dispatch('auth/logout');
      window.location.href = '/login';
    }
    return Promise.reject(error);
  }
);

const appInstance = createApp(App);

appInstance.use(Toast, {
  position: POSITION.TOP_RIGHT,
});
appInstance.use(store);
appInstance.use(router);
appInstance.use(ArgonDashboard);

// Configurar Axios como un plugin Vue para usarlo dentro de los componentes
appInstance.config.globalProperties.$api = axios;

appInstance.mount('#app');
