
//import Cookies from 'js-cookie';
import moment from 'moment-timezone';


function getItemExpiry(key) {
    const itemStr = localStorage.getItem(key);

    // Si el ítem no existe, retornar null
    if (!itemStr) {
        return null;
    }

    const item = JSON.parse(itemStr);
    return item.expiry;
}

function setItemWithExpiration(key, value, ttl) {
    const now = moment();
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone; // Obtener la zona horaria del navegador
    const expiry = now.clone().add(ttl, 'milliseconds').tz(timezone).format();

    const item = {
        value: value,
        expiry: expiry,
    };

    localStorage.setItem(key, JSON.stringify(item));
}

function getItemWithExpiration(key) {
    const itemStr = localStorage.getItem(key);

    // Si el ítem no existe, retornar null
    if (!itemStr) {
        return null;
    }

    const item = JSON.parse(itemStr);
    const now = moment().tz(Intl.DateTimeFormat().resolvedOptions().timeZone); // Obtener la zona horaria del navegador
    const expiry = moment(item.expiry);

    // Si el ítem ha expirado, eliminarlo de `localStorage` y retornar null
    if (now.isAfter(expiry)) {
        localStorage.removeItem(key);
        return null;
    }

    return item.value;
}

const state = {
  token: getItemWithExpiration('token') || '',
  user: null,
  isAuthenticated: !!getItemWithExpiration('token'),
};

const mutations = {
  setToken(state, { token, user }) {
    const ttl = 30 * 60 * 1000; // 1 minuto en milisegundos

    state.token = token;
    state.isAuthenticated = true;
    state.user = user;

    setItemWithExpiration('token', token, ttl);
    //Cookies.set('token', token, { expires: 1, secure: false, httpOnly: false, sameSite: 'Lax' }); // Opcional, si también deseas manejar la cookie
  },
  clearToken(state) {
    state.token = '';
    state.isAuthenticated = false;
    state.user = null;

    localStorage.removeItem('token');
    //Cookies.remove('token');
  },
};





const actions = {
  login({ commit }, { token, user }) {
    commit('setToken', { token, user });
  },
  logout({ commit }) {
    commit('clearToken');
  },

};

const getters = {
  isAuthenticated: state => {
    const token = getItemWithExpiration('token');
    return !!token && state.isAuthenticated;
  },
  token: state => state.token,
  user: state => state.user,
  tokenExpiry: () => getItemExpiry('token'), // Obtener la fecha de expiración del token

};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};