<template>
  <div class="py-7 container-fluid">
    <div class="card w-70">
      <div class="card-header pb-0">
        <h6>Add Project</h6>
      </div>
      <div class="card-body px-5 pt-5 pb-2">
        <div class="mb-3">
          <label for="exampleFormControlInput1" class="form-label">Name</label>
          <input
            type="text"
            class="form-control"
            id="exampleFormControlInput1"
            v-model="namespace.name"
            value="namespace.name"
            :style="{ width: inputWidth }"
            placeholder="Namespace01"
          />
        </div>
        <div class="mb-3">
          <button @click="addNamespace()" type="button" class="btn btn-primary">
            Add
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useToast } from "vue-toastification";

const toast = useToast();
export default {
  components: {},
  data() {
    return {
      namespace: {
        name: "",
      },
    };
  },
  computed: {},
  mounted() {},
  methods: {
    async addNamespace() {
      try {
        const response = await this.$api.post(
          "/v1/namespace/add",
          this.namespace
        );
        if (response.status >= 200 && response.status < 300) {
          toast.success("Namespace create successful");
          this.$router.push({ name: "Namespace" });
        }
      } catch (error) {
        if (error.response && error.response.status === 403) {
          // Redirigir a la página de Forbidden
          this.$router.push("/error/403");
        } else {
          toast.error("Namespace create error");
        }
      }
    },
  },
};
</script>
