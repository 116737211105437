<template>
  <div class="py-7 container-fluid">
    <div class="card">
      <div class="card-header pb-0">
        <p class="card-title">Checking....</p>
      </div>
      <div class="row">
        <div class="col-lg-40">
          <div class="row mt-4">
            <div class="row">
              <div class="col-lg-2 col-md-6 col-12">
                <div class="card m-3 w-100"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  async mounted() {
    try {
      const urlParams = new URLSearchParams(window.location.search);
      const code = urlParams.get("code");
      if (code) {
        const response = await this.$api.get(`/v1/callback?code=${code}`);
        const token = response.data.token;
        const user = response.data.user;
        //const usernameAdmin = response.data.usernameAdmin;
        this.$store.dispatch("auth/login", { token, user });
        this.$router.push("/");
      } else {
        console.error("No se encontró el código de autenticación en la URL");
      }
    } catch (error) {
      console.error("Error procesando la autenticación:", error);
    }
  },
};
</script>
