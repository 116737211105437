<template>
  <div class="py-7 container-fluid">
    <div class="card">
      <div class="card-header pb-3">
        <div class="mb-3">
          <h6 v-if="schedules.length === 0">Schedules Snapshot not found</h6>
          <h6 v-else>Snapshot Schedules</h6>
          <router-link
            class="btn btn-primary btn-sm"
            :to="{
              name: 'ScheduleSnapshotAdd',
            }"
            >Add</router-link
          >
        </div>
      </div>
      <div v-if="schedules.length !== 0" class="card-body px-5 pt-5 pb-2">
        <div class="table-responsive p-0">
          <table class="table align-items-center justify-content-center mb-0">
            <thead>
              <tr>
                <th
                  class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                >
                  #
                </th>
                <th
                  class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
                >
                  Stack
                </th>
                <th
                  class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
                >
                  Namespace
                </th>
                <th
                  class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
                >
                  Cron
                </th>
                <th
                  class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
                >
                  Last run date
                </th>
                <th
                  class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
                >
                  last run status
                </th>
                <th
                  class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
                >
                  Retain days
                </th>
                <th
                  class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
                >
                  Enable
                </th>                
                <th
                  class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
                ></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="schedule in schedules" :key="schedule.id">
                <td>
                  <p class="text-xs font-weight-bold mb-0">{{ schedule.ID }}</p>
                </td>
                <td>
                  <p class="text-xs font-weight-bold mb-0">
                    {{ schedule.stack.name }}
                  </p>
                </td>
                <td>
                  <p class="text-xs font-weight-bold mb-0">
                    {{ schedule.stack.namespace.name }}
                  </p>
                </td>
                <td>
                  <p class="text-xs font-weight-bold mb-0">
                    {{ schedule.cron }}
                  </p>
                </td>
                <td>
                  <p class="text-xs font-weight-bold mb-0">
                    {{ schedule.lastExec }}
                  </p>
                </td>
                <td>
                  <span v-if="schedule.lastExecStatus === true"
                    ><i class="bi bi-check success"></i
                  ></span>
                  <span v-else><i class="bi bi-x"></i></span>
                </td>
                <td>
                  <p class="text-xs font-weight-bold mb-0">
                    {{ schedule.retainDays }}
                  </p>
                </td>
                <td>
                  <span v-if="schedule.disable === false"
                    ><i class="bi bi-check success"></i
                  ></span>
                  <span v-else><i class="bi bi-x"></i></span>
                </td>

                <td>
                  <button
                    style="
                      padding: 0;
                      border: none;
                      background: none;
                      color: var(--bs-primary);
                      text-decoration: none;
                    "
                    @click="deleteSchedule(schedule)"
                  >
                    <i class="bi bi-trash-fill"></i>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
          <nav aria-label="Page navigation example">
            <ul class="pagination justify-content-center">
              <li class="page-item" :class="{ disabled: currentPage === 1 }">
                <button class="page-link" @click="changePage(currentPage - 1)">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-arrow-left-short"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5"
                    />
                  </svg>
                </button>
              </li>

              <li
                class="page-item"
                v-for="page in totalPages"
                :key="page"
                :class="{ active: page === currentPage }"
              >
                <button class="page-link" @click="changePage(page)">
                  {{ page }}
                </button>
              </li>

              <li
                class="page-item"
                :class="{ disabled: currentPage === totalPages }"
              >
                <button class="page-link" @click="changePage(currentPage + 1)">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-arrow-right-short"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8"
                    />
                  </svg>
                </button>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useToast } from "vue-toastification";

const toast = useToast();
export default {
  components: {},
  data() {
    return {
      schedules: [],
    };
  },
  computed: {},
  mounted() {
    this.loadSchedule();
  },
  methods: {
    async deleteSchedule(schedule) {
      try {
        const response = await this.$api.delete(`/v1/schedule/snapshot/${schedule.ID}`);
        if (response.status >= 200 && response.status < 300) {
          toast.success("Schedule snapshot delete successful");
          this.loadSchedule();
        }
      } catch (error) {
        toast.error("Schedule snapshot delete error");
      }
    },
    loadSchedule() {
      this.$api
        .get(`/v1/schedule/snapshot?page=${this.page}&pagesize=${this.pagesize}`)
        .then((response) => {
          this.schedules = response.data;
        })
        .catch((error) => {
          if (error.response && error.response.status === 403) {
            // Redirigir a la página de Forbidden
            this.$router.push("/error/403");
          } else {
            console.error("Error fetching schedule:", error);
          }
        });
    },
  },
};
</script>
