<template>
  <div class="py-7 container-fluid">
    <div class="card">
      <div class="card-header pb-0">
        <div class="mb-3">
          <h6>Home</h6>
        </div>
        <div class="row">
          <div class="card" style="width: 18rem">
            <img src="remote.png" alt="" srcset="" />
          </div>
          <p></p>
          <div class="col-lg-10">
            <div class="row">
              <div class="col-lg-2 col-md-6 col-12">
                <div class="mb-3 card">
                  <div class="p-3 card-body">
                    <div class="d-flex">
                      <div>
                        <div class="numbers">
                          <p
                            class="mb-0 text-sm text-uppercase font-weight-bold"
                          >
                            <i
                              class="text-lg opacity-10 bi bi-collection-fill"
                              aria-hidden="true"
                            ></i>
                            Total Projects
                          </p>

                          <h5 :class="`mb-0 font-weight-bolder`">
                            <span :class="`text-sm font-weight-bolder`">
                              {{ report.namespaces }}</span
                            >
                          </h5>
                          <!--  eslint-disable-next-line vue/no-v-html -->
                          <p class="mt-2 mb-0"></p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-2 col-md-6 col-12">
                <div class="mb-3 card">
                  <div class="p-3 card-body">
                    <div class="d-flex">
                      <div>
                        <div class="numbers">
                          <p
                            class="mb-0 text-sm text-uppercase font-weight-bold"
                          >
                            <i
                              class="text-lg opacity-10 bi bi-stack"
                              aria-hidden="true"
                            ></i>
                            Total Stacks
                          </p>

                          <h5 :class="`mb-0 font-weight-bolder`">
                            <span :class="`text-sm font-weight-bolder`">
                              {{ report.stacks }}</span
                            >
                          </h5>
                          <!--  eslint-disable-next-line vue/no-v-html -->
                          <p class="mt-2 mb-0"></p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-2 col-md-6 col-12">
                <div class="mb-3 card">
                  <div class="p-3 card-body">
                    <div class="d-flex">
                      <div>
                        <div class="numbers">
                          <p
                            class="mb-0 text-sm text-uppercase font-weight-bold"
                          >
                            <i
                              class="text-lg opacity-10 bi bi-stack"
                              aria-hidden="true"
                            ></i>
                            Total Images Stacks
                          </p>

                          <h5 :class="`mb-0 font-weight-bolder`">
                            <span :class="`text-sm font-weight-bolder`">
                              {{ report.images }}</span
                            >
                          </h5>
                          <!--  eslint-disable-next-line vue/no-v-html -->
                          <p class="mt-2 mb-0"></p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-2 col-md-6 col-12">
                <div class="mb-3 card">
                  <div class="p-3 card-body">
                    <div class="d-flex">
                      <div>
                        <div class="numbers">
                          <p
                            class="mb-0 text-sm text-uppercase font-weight-bold"
                          >
                            <i
                              class="text-lg opacity-10 bi bi-archive-fill"
                              aria-hidden="true"
                            ></i>
                            Total snapshots
                          </p>

                          <h5 :class="`mb-0 font-weight-bolder`">
                            <span :class="`text-sm font-weight-bolder`">
                              {{ report.snapshots }}</span
                            >
                          </h5>
                          <!--  eslint-disable-next-line vue/no-v-html -->
                          <p class="mt-2 mb-0"></p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-2 col-md-6 col-12">
                <div class="mb-3 card">
                  <div class="p-3 card-body">
                    <div class="d-flex">
                      <div>
                        <div class="numbers">
                          <p
                            class="mb-0 text-sm text-uppercase font-weight-bold"
                          >
                            <i
                              class="text-lg opacity-10 bi bi-people-fill"
                              aria-hidden="true"
                            ></i>
                            Total Users
                          </p>

                          <h5 :class="`mb-0 font-weight-bolder`">
                            <span :class="`text-sm font-weight-bolder`">
                              {{ report.users }}</span
                            >
                          </h5>
                          <!--  eslint-disable-next-line vue/no-v-html -->
                          <p class="mt-2 mb-0"></p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup></script>
<script>
export default {
  components: {},
  data() {
    return {
      report: Object,
    };
  },
  computed: {},
  mounted() {
    this.loadReport();
  },
  methods: {
    loadReport() {
      this.$api
        .get("/v1/report")
        .then((response) => {
          this.report = response.data;
        })
        .catch((error) => {
          if (error.response && error.response.status === 403) {
            // Redirigir a la página de Forbidden
            window.location.href = "/403.html";
          } else {
            console.error("Error fetching report:", error);
          }
        });
    },
  },
};
</script>
