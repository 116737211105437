<template>
  <div class="py-7 container-fluid">
    <div class="card">
      <div class="card-header pb-6">
        <div class="mb-3">
          <h6 v-if="events.length === 0">Events not found</h6>
          <h6 v-else>Events</h6>
        </div>
      </div>
      <div class="card-body px-5 pt-5 pb-2" v-if="events.length !== 0">
        <div class="mb-3">
          <button class="btn btn-primary btn-sm" @click="cleanAllEvents">
            Clean all
          </button>
        </div>
        <div class="table-responsive p-0">
          <table class="table align-items-center justify-content-center mb-0">
            <thead>
              <tr>
                <th
                  class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
                >
                  Date
                </th>
                <th
                  class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
                >
                  Namespace
                </th>
                <th
                  class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
                >
                  Stack
                </th>
                <th
                  class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
                >
                  Action
                </th>
                <th
                  class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
                >
                  Message
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="event in events" :key="event.id">
                <td>
                  <p class="text-xs font-weight-bold mb-0">
                    {{ event.date }}
                  </p>
                </td>
                <td v-if="event.stack">
                  <p class="text-xs font-weight-bold mb-0">
                    {{ event.stack.namespace.name }}
                  </p>
                </td>
                <td v-else>
                  <p class="text-xs font-weight-bold mb-0">-</p>
                </td>
                <td v-if="event.stack">
                  <p class="text-xs font-weight-bold mb-0">
                    {{ event.stack.name }}
                  </p>
                </td>
                <td v-else>
                  <p class="text-xs font-weight-bold mb-0">-</p>
                </td>
                <td>
                  <p class="text-xs font-weight-bold mb-0">
                    {{ event.action }}
                  </p>
                </td>
                <td>
                  <p class="text-xs font-weight-bold mb-0">
                    {{ event.message }}
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
          <nav aria-label="Page navigation example">
            <ul class="pagination justify-content-center">
              <li class="page-item" :class="{ disabled: currentPage === 1 }">
                <button class="page-link" @click="changePage(currentPage - 1)">
                  <i class="bi bi-arrow-left"></i>
                </button>
              </li>
              <li
                class="page-item"
                v-for="page in totalPages"
                :key="page"
                :class="{ active: page === currentPage }"
              >
                <button class="page-link" @click="changePage(page)">
                  {{ page }}
                </button>
              </li>
              <li
                class="page-item"
                :class="{ disabled: currentPage === totalPages }"
              >
                <button class="page-link" @click="changePage(currentPage + 1)">
                  <i class="bi bi-arrow-right"></i>
                </button>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { useToast } from "vue-toastification";
const toast = useToast();
export default {
  components: {},
  data() {
    return {
      events: [],
      currentPage: 1,
      pageSize: 10,
      total: 0,
    };
  },
  computed: {
    totalPages() {
      return Math.ceil(this.total / this.pageSize);
    },
  },
  mounted() {
    this.loadEvents();
  },
  methods: {
    loadEvents() {
      this.$api
        .get(
          `/v1/event/system?page=${this.currentPage}&pagesize=${this.pageSize}`
        )
        .then((response) => {
          this.events = response.data.events;
          this.total = response.data.total;
        })
        .catch((error) => {
          if (error.response && error.response.status === 403) {
            // Redirigir a la página de Forbidden
            this.$router.push("/error/403");
          } else {
            console.error("Error fetching events:", error);
          }
        });
    },
    async cleanAllEvents() {
      try {
        const response = await this.$api.delete(`/v1/event/system`);
        if (response.status >= 200 && response.status < 300) {
          toast.success("Delete all system events sucessfull");
          this.loadEvents();
        }
      } catch (error) {
        toast.error("event delete error");
      }
    },
    changePage(page) {
      if (page < 1 || page > this.totalPages) return;
      this.currentPage = page;
      this.loadEvents();
    },
  },
};
</script>
