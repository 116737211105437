<template>
  <div class="py-7 container-fluid">
    <div class="card">
      <div class="card-header pb-0">
        <div class="mb-3">
          <h6 v-if="stacks.length === 0">Stacks not found</h6>
          <h6 v-else>Stacks</h6>
          <router-link
            class="btn btn-primary btn-sm"
            :to="{
              name: 'StackAdd',
            }"
            >Add</router-link
          >
        </div>
      </div>
      <div class="row">
        <div class="table-responsive p-0">
          <table class="table align-items-center justify-content-center mb-0">
            <div class="col-lg-10">
              <div class="row mt-4">
                <div class="row">
                  <div
                    class="col-lg-2 col-md-6 col-12"
                    v-for="stack in stacks"
                    :key="stack.name"
                  >
                    <div class="card m-3 w-100">
                      <div class="card-body">
                        <div class="text-sm mb-1">
                          <i class="bi bi-stack font-weight-bold"
                            >Name: {{ stack.name }}</i
                          >
                        </div>
                        <div class="text-sm mb-1">
                          <i class="bi bi-collection-fill font-weight-bold">
                            Project: {{ stack.namespace.name }}</i
                          >
                        </div>
                        <div class="mb-3">
                          <argon-badge
                            variant="fill"
                            size="md"
                            color="secondary"
                          >
                            {{ stack.timezone }}
                          </argon-badge>
                          <argon-badge
                            variant="fill"
                            size="md"
                            color="secondary"
                          >
                            {{ stack.imageStack.name }}
                          </argon-badge>
                          <argon-badge
                            variant="fill"
                            size="md"
                            color="secondary"
                          >
                            Machine {{ stack.machine.name }} ({{
                              stack.machine.memory
                            }}
                            x {{ stack.machine.cpu }})
                          </argon-badge>
                        </div>
                        <div class="mb-3">
                          <!-- Enlace para editar -->
                          <router-link
                            style="
                              padding: 0;
                              border: none;
                              background: none;
                              color: var(--bs-primary);
                              text-decoration: none;
                              margin-right: 10px; /* Espaciado entre botones */
                            "
                            :to="{
                              name: 'StackUpdate',
                              params: { id: stack.ID },
                            }"
                            title="Edit"
                            ><i class="bi bi-pencil-fill"></i> Edit</router-link
                          >
                          <!-- Botón para snapshot -->
                          <button
                            style="
                              padding: 0;
                              border: none;
                              background: none;
                              color: var(--bs-primary);
                              text-decoration: none;
                            "
                            title="Snapshot"
                            @click="createSnapshot(stack)"
                          >
                            <i class="bi bi-archive-fill"></i> Create snapshot
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </table>
          <nav aria-label="Page navigation example">
            <ul class="pagination justify-content-center">
              <li class="page-item" :class="{ disabled: currentPage === 1 }">
                <button class="page-link" @click="changePage(currentPage - 1)">
                  <i class="bi bi-arrow-left"></i>
                </button>
              </li>
              <li
                class="page-item"
                v-for="page in totalPages"
                :key="page"
                :class="{ active: page === currentPage }"
              >
                <button class="page-link" @click="changePage(page)">
                  {{ page }}
                </button>
              </li>
              <li
                class="page-item"
                :class="{ disabled: currentPage === totalPages }"
              >
                <button class="page-link" @click="changePage(currentPage + 1)">
                  <i class="bi bi-arrow-right"></i>
                </button>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ArgonBadge from "@/components/ArgonBadge.vue";
import { useToast } from "vue-toastification";

const toast = useToast();

export default {
  components: { ArgonBadge },
  data() {
    return {
      stacks: [],
      currentPage: 1,
      pageSize: 10,
      total: 0,
    };
  },
  computed: {
    totalPages() {
      return Math.ceil(this.total / this.pageSize);
    },
  },
  mounted() {
    this.loadStacks();
  },
  methods: {
    async createSnapshot(stack) {
      try {
        const response = await this.$api.post("/v1/snapshot", stack);
        if (response.status === 200) {
          toast.success("Snapshot create successful");
        }
      } catch (error) {
        toast.error("Snapshot create error");
      }
    },
    loadStacks() {
      this.$api
        .get(`/v1/stack?page=${this.currentPage}&pagesize=${this.pageSize}`)
        .then((response) => {
          this.stacks = response.data.stacks;
          this.total = response.data.total;
        })
        .catch((error) => {
          if (error.response && error.response.status === 403) {
            // Redirigir a la página de Forbidden
            this.$router.push("/error/403");
          } else {
            console.error("Error fetching stacks:", error);
          }
        });
    },
    changePage(page) {
      if (page < 1 || page > this.totalPages) return;
      this.currentPage = page;
      this.loadStacks();
    },
    updateStack(stack) {
      this.$api
        .get("/v1/stack", stack)
        .then((response) => {
          this.stacks = response.data;
        })
        .catch((error) => {
          console.error("Error update stack:", error);
        });
    },
  },
};
</script>
